
.filepond--panel-root {
    background-color: rgb(63, 191, 211)
}

.filepond--file {
    color: white;
}

.filepond--drop-label {
    color: #fff;
}

/* underline color for "Browse" button */
.filepond--label-action {
    text-decoration-color: #fff;
}

