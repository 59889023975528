/* .Toastify__toast-body {
    color: #444C63    ;
    font-size: 16px;
    padding-left: 20px;
    line-height: 20px;
    padding: 0px;
    padding-top: 25px;
    width: 150%;
    height: 150px;
    font-weight: 400;
    margin-left: 25px !important;
    
  }  */

.Toastify__toast--info {
    word-break: break-all;
    width: 150%;
  }