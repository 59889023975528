.Mui-selected {
    font-weight: bold !important;
}

.react-datepicker__input-container input {
    display: flex;
    height: 30px;
    width: 90px;
    margin-left: 4px;
    border-radius: 3px;
    border: 1px solid lightgray;
}

.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
  